import React, { useEffect, useState } from "react";

import BackgroundContainer from "../../components/BackgroundContainer";
import Header from "../../components/Header";
import Navigator from "../../components/Navigator";
import ClientsSwiper from "../../components/ClientsSwiper";
import PointLight from "../../components/PointLight";

export default function Clients() {
  return (
    <div className="Clients flex flex-col h-full">
      <Header selectedId="clients" device="desktop"/>
      <BackgroundContainer className="flex items-center justify-center px-4 2xl:px-16 py-12 leagueSpartanFont">
        <div 
          className="h-fit z-20 max-w-[1100px]"
        >
          <ClientsSwiper />
        </div>
        <div className="z-10 absolute flex justify-center items-center">
          <PointLight 
            larger
            tiny
          />  
        </div>
        <Navigator fill selectedId="clients"/>
      </BackgroundContainer>
    </div>
  );
}