import React from 'react'
import Link from './Link'
import { motion } from 'framer-motion';
import DesktopItems from './DesktopItems';
import MobileItems from './MobileItems';

interface HeaderProps {
  selectedId?: "home" | "company" | "clients" | "contact" | "events" | "products";
  device?: "mobile" | "desktop"
}

export interface RouteInfo {
  id: string;
  route: string;
  content: string;
}

const headerData: RouteInfo[] = [
  {
    id: "home",
    route: "/",
    content: "Home"
  },
  {
    id: "company",
    route: "/empresa",
    content: "Empresa"
  },
  {
    id: "clients",
    route: "/clientes",
    content: "Clientes"
  },
  {
    id: "contact",
    route: "/contato",
    content: "Contato"
  },
  {
    id: "events",
    route: "/eventos",
    content: "Eventos"
  },
  {
    id: "products",
    route: "/produtos",
    content: "Produtos e serviços"
  }
]

export default function Header({selectedId, device}: HeaderProps) {
  return (
    <motion.header 
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{ type: "tween", ease: "anticipate", duration: 0.5 }}
      className={`sticky top-0 z-40 ${device === "mobile" ? "some-mobile-class" : ""}`}
    >
      {device == "desktop" &&
        <DesktopItems 
          selectedPageId={selectedId}
          headerData={headerData}
        />
      }

      {device == "mobile" &&
        <MobileItems 
          selectedPageId={selectedId}
          headerData={headerData}
        />
      }
    </motion.header>
  )
}