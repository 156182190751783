import React, { ReactNode, useState } from "react";
import Map from "../Map";
import Typist from "react-typist";

interface FindUsContentProps {
  mobile?: boolean;
}

export default function FindUsContent({mobile}: FindUsContentProps) {
  const [hovering, setHovering] = useState(false);
  const text = "Clique para ver mais";

  const redirectToGoogleMaps = () => {
    window.open(`https://www.google.com/maps?q=-12.9317834,-38.3590751`, '_blank');
  };

  const RenderInfo = ({children}: {children: ReactNode}) => (
    <h3 className={`text-white text-lg font-semibold text-left flex ${mobile ? 'mx-8' : 'items-center mx-16'} gap-x-2`}>
      {children}
    </h3>
  );

  return (
    <div className="flex flex-col gap-y-4 justify-center h-full">
      <RenderInfo>
        <img className="h-5" src="https://upcdn.io/12a1z8V/raw/abeventos/images/location.png" alt="" />
        Rua Estevam Barbosa Alves, 25 - Itapuã, Salvador - BA, 41610-625
      </RenderInfo>

      <RenderInfo>
        <img className="h-5" src="https://upcdn.io/12a1z8V/raw/abeventos/images/tel.png" alt="" />
        (71) 3211-7342
      </RenderInfo>

      <RenderInfo>
        <img className="h-5" src="https://upcdn.io/12a1z8V/raw/abeventos/images/wpp.png" alt="" />
        (71) 99616-7544
      </RenderInfo>

      <RenderInfo>
        <img className="h-5" src="https://upcdn.io/12a1z8V/raw/abeventos/images/ig.png" alt="" />
        @abeeventos1
      </RenderInfo>

      <RenderInfo>
        <img className="h-5" src="https://upcdn.io/12a1z8V/raw/abeventos/images/mail.png" alt="" />
        comercial@abeventos.com
      </RenderInfo>

      <div 
        className={`bg-white w-auto relative ${mobile ? 'mx-6' : 'mx-16'} cursor-pointer`}
        style={{borderRadius: '10px'}}
      >
        <div
          className={`transition duration-500 ease-in-out ${hovering ? 'blur-sm' : 'blur-none'}`}
          style={{borderRadius: '10px'}}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={redirectToGoogleMaps}
        >
          <Map />
        </div>
        {(hovering && !mobile) && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
            <Typist className="font-bold" cursor={{ show: false }}>
              {text}
            </Typist>
          </div>
        )}
      </div>
    </div>
  );
}