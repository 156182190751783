import { motion } from "framer-motion";
import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type FlexPosition = "start" | "center" | "bottom";

interface MobileHomeContainerProps {
  direction: "upper" | "bottom";
  align?: FlexPosition;
  justify?: FlexPosition;
  gap?: number;
  flex?: "col" | "row";
  children?: ReactNode;
}

export default function MobileHomeContainer({direction, children, align = "center", justify="center", flex="row", gap=0}: MobileHomeContainerProps) {
  const backgroundImages = {
    ["upper"]: "https://upcdn.io/12a1z8V/raw/abeventos/images/upper-rect.png",
    ["bottom"]: "https://upcdn.io/12a1z8V/raw/abeventos/images/lower-rect.png"
  }

  return (
    <motion.div 
      initial={{opacity: 0, x: '100vw'}}
      animate={{opacity: 0.95, x: 0}}
      transition={{
        type: 'spring',
        stiffness: 50,
        damping: 10
      }}
      className="relative"
    >
      <img
        src={backgroundImages[direction]}
        // className={`${direction === "bottom" && 'max-h-52'} w-full h-auto`}
        className={`${direction === "bottom" ? 'max-h-52' : 'max-h-80'} w-full h-auto`}
      />
      <div className={`absolute top-0 left-0 w-full h-full flex flex-${flex} px-4 items-${align} justify-${justify} gap-${gap}`}>
        {children}
      </div>
    </motion.div>
  );
}