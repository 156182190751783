import { motion } from "framer-motion";
import React from "react";

export default function BackgroundVideo() {
  return (
    <motion.div 
      className="BackgroundVideo absolute z-1 select-none"
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      transition={{
        type: 'spring', 
        stiffness: 60,
        damping: 20
      }}
    >
      <div className="w-screen h-screen">
        <video 
          autoPlay 
          muted 
          playsInline
          controls={false}
          style={{ pointerEvents: "none" }}
          loop 
          className="object-cover w-full h-full"
          onPlaying={(e) => {
            e.preventDefault();
          }}
        >
          <source src="https://upcdn.io/12a1z8V/raw/abeventos/videos/credentechbackground%20(1).mp4" type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-black opacity-40 z-10" />
      </div>
    </motion.div>
  );
}