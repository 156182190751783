import React, { useEffect, useState } from "react"
import Home from "./pages/Deskop/Home";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import BackgroundVideo from "./components/BackgroundVideo";
import Header from "./components/Header";
import { ActiveSectionProvider } from "./hooks/useActiveSection";
import { FaWhatsapp } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";

import 'react-tooltip/dist/react-tooltip.css'

export default function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 920);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 920);
    })
  }, []);

  return (
    <ActiveSectionProvider>
      <div className={`App ${isMobile && 'h-[550vh]'} relative`}>
        {isMobile && (
          <Header device="mobile"/>
        )}
        <BackgroundVideo />
        <div className={`absolute max-h-screen h-screen flex content z-10 ${!isMobile && 'overflow-hidden'}`}>
          <BrowserRouter>
            <Router mobile={isMobile} />
          </BrowserRouter>
          <div 
            className="fixed bottom-10 right-10"
            style={{zIndex: 99999}}
          >
            <a 
              className="block w-full h-full bg-white p-4 rounded-full shadow-xl translate-y-1 hover:translate-y-0 cursor-pointer transition-all duration-300"
              data-tooltip-id="whatsapp-btn"
              data-tooltip-content="Mande uma mensagem no whatsapp!"
              href="https://wa.me/557196167544"
              target="_blank"
            >
              <FaWhatsapp 
                color="green"
                size={32} 
              />
              <Tooltip
                id="whatsapp-btn"
              />
            </a>
          </div>
        </div>
      </div>
    </ActiveSectionProvider>
  );
}
