import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from "swiper/react";
import { Controller } from 'swiper/modules';
import ClientContainer from "../ClientContainer";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

import 'swiper/css';
import 'swiper/css/navigation';

interface ClientsSwiperProps {
  mobile?: boolean;
}

interface ClientInfo {
  name: string;
  desc: string;
  message: string;
  tag: string;
  img: string;
  review: number;
}

const clients: ClientInfo[] = [
  {
    name: "AEROPORTO (Salvador Bahia Airport)" ,
    desc: "Departamento de Marketing",
    message: `"Vocês são parceiros e conseguiram fazer a entrega ainda melhor do que foi idealizado por nós. O projeto do Tour 360 do nosso Complexo de Sustentabilidade foi elaborado com muito carinho pelo Jaime e a equipe da ABE, desde as gravações até as ações que fizemos em congressos, vocês foram excelentes na entrega. Muito bom trabalhar com gente assim, que cola de verdade. Obrigada! 🥰🥰🥰"`,
    tag: "Diana Caires",
    img: 'https://upcdn.io/12a1z8V/raw/abeventos/images/fpciphk5jtcjifmicwtg.png',
    review: 5
  },
  {
    name: "SOMARTS" ,
    desc: "Diretor - Somarts",
    message: `"Trabalhamos com a ABE há anos para a criação de conteúdo digital e aluguel de totens em nossos eventos e não poderíamos estar mais satisfeitos com os resultados! A equipe da ABE é verdadeiramente talentosa e comprometida em oferecer conteúdo criativo e impactante que ressoa com nossa marca e nosso público."`,
    tag: "Roberto Amaral",
    img: 'https://upcdn.io/12a1z8V/raw/abeventos/images/mw6jjtdn6sqscx3a1pyd.png',
    review: 5
  },
  {
    name: "EVENTUS SYSTEM" ,
    desc: "Diretor - Eventus System",
    message: `"Contratamos o serviço de internet com link dedicado para nosso evento XVII Jornada de Neurologia da Bahia e ficamos extremamente satisfeitos com o resultado! A qualidade e confiabilidade da conexão foram excelentes. O ATENDIMENTO DA EQUIPE ABE, COMO SEMPRE, IMPECÁVEIS!"`,
    tag: "Leão Ferreira",
    img: 'https://upcdn.io/12a1z8V/raw/abeventos/images/lbjqfrsxlrya9gyvhviy.png',
    review: 5
  },
  {
    name: "SINDSEFAZ" ,
    desc: "Assessora de Imprensa - SINDSEFAZ",
    message: `"O nosso 3º Congresso sobre a Importância do Fisco ficou muito melhor com a ABE Eventos. Eles tornaram o Congresso mais funcional com os equipamentos de alta tecnologia e divertido com os totens de fotografia. Todos da equipe deram total suporte e atenção para a gente ter um Congresso de sucesso.  Muito Obrigada!"`,
    tag: "Ana Paula Galindo",
    img: 'https://upcdn.io/12a1z8V/raw/abeventos/images/sdjuwwk805ikfttv25kw.png',
    review: 5
  },
]

export default function ClientsSwiper({mobile}: ClientsSwiperProps) {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className={`flex ${mobile ? 'flex-col items-center my-12' : 'gap-2 2xl:gap-8'}`}>
        <button className="text-white z-40" onClick={() => swiperControl!.slidePrev()}>
          {mobile ? (
            <FaArrowUp size={30} className="mb-4" />
          ) : (
            <FaArrowLeft size={30} className="hover:scale-110 transition-all duration-300" />
          )}
        </button>
        <Swiper
          onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
          onSlideChange={() => swiperControl && setActiveIndex(swiperControl.activeIndex)}
          className={`clients-slider ${mobile && 'h-[500px]'}`}
          direction={mobile ? 'vertical' : 'horizontal'}
          slidesPerView={mobile ? 1 : 3}
          loop={true}
          modules={[Controller]}
          allowTouchMove={!mobile}
          centeredSlides={true}
        >
          {clients.map((client) => (
            <SwiperSlide>
              <ClientContainer
                client={client.name}
                desc={client.desc}
                message={client.message}
                tag={client.tag}
                review={client.review}
                img={client.img}
                mobile={mobile}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <button className="text-white" onClick={() => swiperControl!.slideNext()}>
          {mobile ? (
            <FaArrowDown size={30} className="mt-4" />
          ) : (
            <FaArrowRight size={30} className="hover:scale-110 transition-all duration-300" />
          )}
        </button>
      </div>
    </>
  );
}