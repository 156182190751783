import React, { useEffect, useState } from "react";

interface LinkProps extends React.HTMLAttributes<HTMLElement> {
  selected?: boolean;
  route?: string;
  children: React.ReactNode;
}

export default function Link({route, selected, children}: LinkProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<string>('');

  const selectedTextColor = 'linear-gradient(to bottom, #FDCE89, #FFA41C)';
  const hoveringTextColor = 'linear-gradient(to bottom, #FFF8ED, #FFC56F)';
  const defaultTextColor = '#FFF';

  useEffect(() => {
    if (selected) {
      setTextColor(selectedTextColor);
      return;
    }

    if (isHovering) {
      setTextColor(hoveringTextColor);
      return;
    }

    setTextColor(defaultTextColor);
  }, [selected, isHovering]);

  return (
    <li 
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={`text-xs xl:text-base transition-opacity duration-300 ease-out cursor-pointer text-clip-style ${selected ? 'font-black' : 'font-bold'}`}
      style={{
        background: textColor,
        opacity: isHovering || selected ? 1 : 0.9
      }}
    >
      <a href={route}>
        {children}
      </a>
    </li>
  );
}