import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Deskop/Home";
import Render from "./pages/Mobile/Render";
import Company from "./pages/Deskop/Company";
import Clients from "./pages/Deskop/Clients";
import Contact from "./pages/Deskop/Contact";
import Events from "./pages/Deskop/Events";
import Products from "./pages/Deskop/Products";
import { useActiveSection } from "./hooks/useActiveSection";

interface RouterProps {
  mobile?: boolean;
}

export const locationToPath: {[key: string]: string} = {
  ["empresa"]: "company",
  ["clientes"]: "clients",
  ["contato"]: "contact",
  ["eventos"]: "events",
  ["produtos"]: "products"
}

export default function Router({mobile}: RouterProps) {
  const location = useLocation();

  const { setActiveSection } = useActiveSection();

  useEffect(() => {
    if (location.pathname === "/cpanel") {
      window.location.href = "/cpanel";
      return;
    }

    if (mobile) {
      const path = location.pathname.replace("/", "");
      const section = path ? locationToPath[path] : "home";
      setActiveSection(section);
    }
  }, [location.pathname, mobile, setActiveSection]);

  return (
    <Routes location={location} key={location.pathname}>
      {mobile ? (
        <Route path="/*" element={<Render />} />
      ) : (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/empresa" element={<Company />} />
          <Route path="/clientes" element={<Clients />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/eventos" element={<Events />} />
          <Route path="/produtos" element={<Products />} />
        </>
      )}
    </Routes>
  );
} 