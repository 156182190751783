import React, { useEffect, useState } from "react";

interface LinkProps extends React.HTMLAttributes<HTMLElement> {
  selected?: boolean;
  route?: string;
  children: React.ReactNode;
}

export default function Link({route, selected, children}: LinkProps) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [color, setColor] = useState<string>('');
  const [size, setSize] = useState<string>('');

  const selectedSize = "0.875rem";
  const defaultSize = "0.625rem";

  const selectedColor = "#FFF";
  const defaultColor = "#979797";

  useEffect(() => {
    if (selected) {
      setColor(selectedColor);
      setSize(selectedSize);
      return;
    }

    setColor(defaultColor);
    setSize(defaultSize);
  }, [selected]);

  return (
    <li
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <a href={route}>
        <div className="rounded-full"
          style={{
            background: color,
            height: size,
            width: size,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            transform: isHover ? "scale(1.2)" : "scale(1)",
            boxShadow: isHover && color === "#FFF" ? "0 0 4px 2px rgba(255, 255, 255, 0.6)" : "none",
          }}
        >
          {children}
        </div>
      </a>
    </li>
  );
}