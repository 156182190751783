import { motion } from "framer-motion";
import React from "react";

interface BackgroundContainerProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  className?: string;
  style?: {[key: string]: any};
}

export default function BackgroundContainer({children, className, style}: BackgroundContainerProps) {
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 0.95}}
      exit={{opacity: 0}}
      transition={{ type: "tween", ease: "anticipate", duration: 1 }}
      className={`bg-black flex-grow bg-contain bg-no-repeat w-full" ${className}`}
      style={style}
    >
      {children}
    </motion.div>
  );
}