import React from "react";

export default function BudgetButton() {
  return (
    <a 
      href="/contato"
      type="button" 
      className={`select-none bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] w-44 2xl:w-48 p-3 text-xs 2xl:text-sm font-black rounded-md mb-8 flex gap-x-2 mt-4`}
    >
      <img className="h-4" src="https://upcdn.io/12a1z8V/raw/abeventos/images/coin.png" alt="vetor de moedas" />
      Solicite um orçamento
    </a>
  );
}