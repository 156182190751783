import React from "react";

interface FindUsTitleProps {
  mobile?: boolean;
}

export default function FindUsTitle({mobile}: FindUsTitleProps) {
  return (
    <h1 className={`text-white text-5xl font-bold leagueSpartanFont ${mobile ? '' : 'relative top-5'} 2xl:top-20`}>Onde nos encontrar?</h1>
  );
}