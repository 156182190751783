import React, { useState } from 'react'
import Modal from 'react-responsive-modal';

interface containerProps{
  title: string;
  text: string;
  mobile?: boolean;
  onOpen: () => void;
}

export default function ProductContainer({
  mobile,
  title,
  text,
  onOpen
}: containerProps){
  return (
    <div className={`p-2 lg:p-4 border-t border-r border-l border-b rounded-xl border-white border-solid ${mobile ? 'w-44 h-32 flex flex-col justify-between' : 'w-64 2xl:w-80'} bg-gradient-to-b from-transparent to-black product-container flex flex-col justify-between`}>
      <div>
        <h3 className="text-white text-sm 2xl:text-base font-black roboto">{title}</h3>

        <p className="text-white font-medium text-xs max-w-96">
          {text}
        </p>    
      </div>

      <button 
        className={`see-more-button text-black font-bold bg-white p-1 px-2 rounded text-xs flex items-center gap-x-1 max-w-24 text-center ${mobile ? 'self-center' : 'mt-2 2xl:mt-6'}`}
        onClick={onOpen}
      >
        +
        Veja mais
      </button>
    </div>
  )
}