import CountUp from "react-countup";
import { FaCalendarCheck } from "react-icons/fa6";

const easingExpoInOut = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
  t--;
  return c / 2 * (-Math.pow(2, -15 * t) + 2) + b;
};

interface ServedStatisticProps {
  small?: boolean;
  breakLine?: boolean;
}

export default function ServedStatistic({ 
  small = false,
  breakLine = false
}: ServedStatisticProps) {
  return (
    <div>
      <div className="flex gap-2 items-center">
        <h2 className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] bg-clip-text text-transparent text-xl md:text-3xl font-bold clash-display">
          <CountUp 
            end={100} 
            duration={2.5} 
            suffix="+"
            easingFn={easingExpoInOut} 
            useEasing={true} 
            preserveValue={true} 
          />
        </h2>
        <FaCalendarCheck size={small ? 15 : 25} color="#FFA41C" />
      </div>
      <h3 className={`${small ? 'text-xs' : 'text-sm'}`}>
        Eventos corporativos de grande {breakLine && <br />}
        porte atendidos com sucesso {breakLine && <br />}
        nos últimos 12 meses.
      </h3>
    </div>
  );
}
