import React, { useState } from "react";
import { motion } from 'framer-motion';

const spring = {
  type: 'spring',
  stiffness: 300,
  damping: 30
};

interface BottomSheetProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function BottomSheet({
  open,
  onClose,
  children
}: BottomSheetProps) {
  const [dragStartPoint, setDragStartPoint] = useState<number>(0);

  const closeSheet = () => onClose();

  return (
    <div style={{zIndex: 99999}}>
      {open && (
        <motion.div
          drag="y"
          dragConstraints={{ top: 0, bottom: 300 }}
          dragElastic={0.2}
          onDragStart={(event, info) => setDragStartPoint(info.point.y)}
          onDragEnd={(event, info) => {
            const dragDistance = info.point.y - dragStartPoint;
            if (dragDistance > 100) {
              closeSheet();
            }
          }}
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
          transition={spring}
          className="fixed z-50 bottom-0 left-0 right-0 h-1/2 bg-black rounded-t-lg shadow-lg p-4 text-white border-t-2 border-white"
          style={{ touchAction: 'none' }}
        >
          <div className="w-12 h-0.5 bg-white rounded-full mb-4 mx-auto"></div>
          <div className="h-full flex flex-col items-center justify-center">
            {/* <h2 className="text-xl font-bold">Meu Bottom Sheet</h2>
            <p>Pode arrastar para baixo para fechar!</p>
            <button
              onClick={closeSheet}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
            >
              Fechar
            </button> */}
            {children}
          </div>
        </motion.div>
      )}
    </div>
  );
}