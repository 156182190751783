import React from "react";
import CountUp from "react-countup";
import { IoTriangleSharp } from "react-icons/io5";

const easingExpoInOut = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
  t--;
  return c / 2 * (-Math.pow(2, -15 * t) + 2) + b;
};

interface ApprovalStatisticProps {
  small?: boolean;
  breakLine?: boolean;
}

export default function ApprovalStatistic({ 
  small = false,
  breakLine = false
}: ApprovalStatisticProps) {
  return (
    <div>
      <div className="flex items-center gap-2">
        <h2 className="bg-gradient-to-t from-[#00B47E] to-[#91EF48] bg-clip-text text-transparent text-xl md:text-3xl font-bold clash-display">
          <CountUp 
            end={98} 
            duration={2.5} 
            suffix="%" 
            easingFn={easingExpoInOut} 
            useEasing={true} 
            preserveValue={true} 
          />
        </h2>
        <IoTriangleSharp size={small ? 10 : 15} color="#00B47E" />
      </div>
      <h3 className={`${small ? 'text-xs' : 'text-sm'}`}>
        De aprovação dos clientes nos {breakLine && <br />}
        eventos realizados, com {breakLine && <br />}
        feedbacks positivos sobre {breakLine && <br />}
        inovação e confiabilidade.
      </h3>
    </div>
  );
}