import React, { useCallback, useEffect, useRef, useState } from "react";

import MobileHomeContainer from "../../components/MobileHomeContainer";
import LeaderStatistc from "../../components/StatistcsPhrases/Leader";
import ApprovalStatistic from "../../components/StatistcsPhrases/Approval";
import HomeTitle from "../../components/HomeTitle";
import BudgetButton from "../../components/BudgetButton";
import CompanyTitle from "../../components/CompanyTitle";
import CompanySubtitle from "../../components/CompanySubtitle";
import CompanyAbout from "../../components/CompanyAbout";
import ViewSolutions from "../../components/ViewSolutions";
import ClientsSwiper from "../../components/ClientsSwiper";
import ContactTitle from "../../components/ContactTitle";
import ContactForm from "../../components/ContactForm";
import FindUsTitle from "../../components/FindUsTitle";
import FindUsContent from "../../components/FindUsContent";
import FeaturedEvent from "../../components/FeaturedEvent";
import RecentEvents from "../../components/RecentEvents";
import ProductsList from "../../components/ProductsList";
import { useActiveSection } from "../../hooks/useActiveSection";

import 'react-spring-bottom-sheet/dist/style.css'
import BottomSheet from "../../components/BottomSheet";
import { EventInfo, Events, FeaturedEvent as FeaturedEventData } from "../../services/EventsInfo";
import { ProductInfo, Products } from "../../services/ProductsInfo";
import GrowthStatistic from "../../components/StatistcsPhrases/Growth";
import ServedStatistic from "../../components/StatistcsPhrases/Served";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Controller, Navigation } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Control } from "leaflet";

export default function Render() {
  const [swiperEventControl, setSwiperEventControl] = useState<SwiperClass>();
  const [swiperProductControl, setSwiperProductControl] = useState<SwiperClass>();

  const [eventSheetOpen, setEventSheetOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInfo>();

  const [productSheetOpen, setProductSheetOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInfo>();

  const { activeSection, setActiveSection } = useActiveSection();

  useEffect(() => {
    if (activeSection) {
      const sectionElement = document.getElementById(activeSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [activeSection]);

  const handleEventSelect = (event: EventInfo) => {
    setSelectedEvent(event);
    setEventSheetOpen(true)
  }

  const handleEventSheetClose = () => {
    setEventSheetOpen(false);
    setSelectedEvent(undefined);
  }

  const handleProductSelect = (product: ProductInfo) => {
    setSelectedProduct(product);
    setProductSheetOpen(true);
  }

  const handleProductSheetClose = () => {
    setProductSheetOpen(false);
    setSelectedEvent(undefined);
  }

  return (
    <div className="Render relative h-auto">
      <div 
        className="w-full h-screen z-10 absolute flex flex-col justify-between gap-8 xs:gap-24"
        key="home"
        id="home"
      >
        <MobileHomeContainer 
          direction="upper" 
          align="start"
          justify="start"
          flex="col"
          gap={2}
        >
          <HomeTitle />
          <div className="grid grid-cols-2 text-white gap-4">
            <LeaderStatistc small />
            <ServedStatistic small />
            <ApprovalStatistic small />
            <GrowthStatistic small />
          </div>
        </MobileHomeContainer>
        <MobileHomeContainer 
          direction="bottom"
          align="center"
          justify="center"
          flex="col"
        >
          <BudgetButton/>
        </MobileHomeContainer>
      </div>
      <div className="relative top-full">
        <div className="px-8 flex flex-col gap-16">
          <div className="flex flex-col gap-6">
            <div 
              className="flex flex-col gap-6"
              id="company"
              key="company"
            >
              <CompanyTitle />
              <CompanySubtitle mobile />
            </div>
            <CompanyAbout />
            <div className="m-auto mt-6">
              <ViewSolutions mobile />
            </div>
          </div>
          <div
            id="clients"
            key="clients"
          >
            <h2 className='text-white text-center text-4xl 2xl:text-6xl max-w-xl font-bold leagueSpartanFont'>
              Nossos <span className="underline decoration-solid">clientes</span><br/>
            </h2>
            <div className="px-6">
              <ClientsSwiper mobile />
            </div>
          </div>
          <div
            className="text-center"
          >
            <ContactTitle mobile />
            <div 
              id="contact" 
              key="contact" 
              style={{
                width: '100%',
                height: '50px',
              }}
            />
            <ContactForm mobile />
            <div className="w-full h-0.5 bg-white my-8" />
          </div>
          <div className="text-center flex flex-col gap-4">
            <FindUsTitle mobile />
            <FindUsContent mobile />
          </div>
          <div
            id="events"
            key="events"
            className="text-center"
          >
            <h2 className='text-white text-4xl 2xl:text-6xl max-w-xl font-bold leagueSpartanFont'>
              Evento <span className="underline decoration-solid">destaque</span><br/>
            </h2>
            <FeaturedEvent
              event={FeaturedEventData}
              onEventClick={() => handleEventSelect(FeaturedEventData)}
              mobile
            />
          </div>
          <div
            className="flex flex-col items-center gap-4"
          >
            <h2 className='text-white text-4xl 2xl:text-6xl max-w-xl font-bold leagueSpartanFont'>
              Eventos <span className="underline decoration-solid">recentes</span><br/>
            </h2>
            <div className="m-auto mt-4">
              <RecentEvents 
                events={Events}
                onEventClick={handleEventSelect} 
                mobile
              />
            </div>
          </div>
          <BottomSheet 
            open={eventSheetOpen}
            onClose={handleEventSheetClose}
          >
            {selectedEvent && (
              <div className="flex flex-col items-center">
                <h2 className="font-semibold mb-4 text-sm text-center">{selectedEvent?.title}</h2>
                <div className="max-w-80 flex">
                  <button
                    onClick={() => swiperEventControl!.slidePrev()}
                    className="z-40 text-[#ff9900]"
                  >
                    <IoIosArrowBack
                      size={35}
                    />
                  </button>
                  <Swiper
                    onSwiper={(swiper: SwiperClass) => setSwiperEventControl(swiper)}
                    direction="horizontal"
                    loop={true}
                    slidesPerView={1}
                    modules={[Navigation, Controller]}
                  >
                    {selectedEvent.imgsUrl.map((imgUrl) => (
                      <SwiperSlide key={imgUrl}>
                        <img
                          className="w-64 h-28 object-cover"
                          src={imgUrl}
                        />      
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <button
                    onClick={() => swiperEventControl!.slideNext()}
                    className="z-40 text-[#ff9900]"
                  >
                    <IoIosArrowForward
                      size={35}
                    />
                  </button>
                </div>
                <p className="px-2 text-center mt-1 text-xs">
                  {selectedEvent?.description}
                </p>
              </div>
            )}
          </BottomSheet>
          <div
            id="products"
            key="products"
            className="flex flex-col items-center gap-4"
          >
            <h2 className='text-white text-4xl 2xl:text-6xl max-w-xl font-bold leagueSpartanFont'>
              Nossos serviços
            </h2>
            <ProductsList 
              products={Products}
              onProductOpen={handleProductSelect}
              mobile
            />
          </div>
          <BottomSheet 
            open={productSheetOpen}
            onClose={handleProductSheetClose}
          >
            {selectedProduct && (
              <div className="w-96">
                <h2 className="font-semibold mt-6 text-base text-center">{selectedProduct.title}</h2>
                {/* <p className="px-2 text-center mt-1 text-xs mb-4">
                  {selectedProduct.text}
                </p> */}
                <div className="div-scroll text-sm text-gray-300 max-h-40 mx-4 mb-4 overflow-y-auto leading-relaxed" dangerouslySetInnerHTML={{__html: selectedProduct!.text}} />
                {selectedProduct?.videoUrl && (
                  <div className="relative group mt-4">
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl z-10" />
                    <video 
                      className="w-full h-48 object-cover rounded-xl transition-transform duration-300 group-hover:scale-[1.02]"
                      src={selectedProduct.videoUrl}
                      autoPlay 
                      muted 
                      loop 
                      playsInline
                    />
                  </div>
                )}
                {selectedProduct?.imagesUrl && (
                  <div className="flex m-auto">
                    {selectedProduct.imagesUrl.length > 1 && (
                      <button
                        onClick={() => swiperProductControl!.slidePrev()}
                        className="z-40 text-[#ff9900]"
                      >
                        <IoIosArrowBack
                          size={35}
                        />
                      </button>
                    )}
                    <Swiper
                      onSwiper={(swiper: SwiperClass) => setSwiperProductControl(swiper)}
                      direction="horizontal"
                      loop={true}
                      slidesPerView={1}
                      modules={[Navigation, Controller]}
                    >
                      {selectedProduct.imagesUrl.map((imageUrl) => (
                        <SwiperSlide key={imageUrl}>
                          <img
                            className="w-full h-48 object-cover rounded-xl"
                            src={imageUrl}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {selectedProduct.imagesUrl.length > 1 && (
                      <button
                        onClick={() => swiperProductControl!.slideNext()}
                        className="z-40 text-[#ff9900]"
                      >
                        <IoIosArrowForward
                          size={35}
                        />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </BottomSheet>
          <div className="m-auto pt-6 pb-16">
            <BudgetButton/>
          </div>
        </div>
      </div>
    </div>
  );
}