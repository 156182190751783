import React from "react";

export default function CompanyAbout() {
  return (
    <section className="flex flex-col mt-6 gap-y-4 2xl:grow">
      <div className="flex flex-col">
        <h2 className="text-white text-lg 2xl:text-xl font-black mb-1">
          Sobre a ABE Eventos
        </h2>

        <p 
          className="text-white font-medium text-xs md:max-w-lg lg:text-sm lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl"
        >
          Na ABE Eventos, estamos comprometidos em sermos líderes no
          fornecimento de soluções criativas e tecnológicas para eventos
          corporativos. Nosso foco é proporcionar experiências inovadoras que
          elevem o padrão dos seus eventos, garantindo não apenas a satisfação dos
          participantes, mas também o alcance dos objetivos de comunicação e
          marketing de nossos clientes.
        </p>
      </div>
    
      <div className="flex flex-col">
        <h2 className="text-white text-lg 2xl:text-xl font-black mb-1">
          O que nos move
        </h2>

        <p 
          className="text-white font-medium text-sm md:max-w-lg lg:text-md lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl"
        >
          Queremos ser reconhecidos como o parceiro ideal para empresas que
          buscam excelência na organização e execução de eventos. Oferecemos as
          melhores estratégias e soluções para transformar sua visão em realidade,
          proporcionando eventos únicos que refletem a identidade e os valores da sua
          marca.
        </p>

        <p 
          className="text-white text-sm lg:text-sm xl:text-md max-w-lg xl:max-w-2xl font-medium mt-4"
        >
          A ABE não apenas planeja, organiza e dá vida ao seu evento, vamos muito
          além das expectativas em atender suas necessidades.
        </p>
      </div>
    </section>
  );
}