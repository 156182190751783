export interface EventInfo {
  imgsUrl: string[];
  title: string;
  description: string;
}

export const FeaturedEvent: EventInfo = {
  title: "XXV Congresso Nacional do Ministério Público (CNMP)",
  description: "Em parceria com a Eventus System, a ABE Eventos marcou presença no XXV Congresso Nacional do Ministério Público, oferecendo soluções modernas para aprimorar a experiência dos participantes. Disponibilizamos Totens Interativos e Totens de Credenciamento, que agilizaram o processo de acesso e engajamento. Além disso, nossa equipe cuidou da Produção de Conteúdo, assegurando uma comunicação clara e envolvente para os congressistas e elevando o evento a um novo patamar de interatividade e eficiência.",
  imgsUrl: [
    "https://upcdn.io/12a1z8V/raw/abeventos/images/gmqvsr5tekaihislgndt.jpg",
    "https://upcdn.io/12a1z8V/raw/abeventos/images/rsxomhtuha1agytrsqjx.jpg",
    "https://upcdn.io/12a1z8V/raw/abeventos/images/d8o1sre4joetxfaq0l0i.jpg",
    "https://upcdn.io/12a1z8V/raw/abeventos/images/h8dwzjmbjntuem6vjxdd.jpg",
  ]
}

export const Events: EventInfo[] = [
  {
    title: "III ESG Fórum Salvador",
    description: "Para o III ESG Fórum Salvador, atendendo ao cliente Salvador Bahia Airport, a ABE Eventos marcou presença no stand do Aeroporto de Salvador. Em parceria com o Aeroporto, a ABE desenvolveu um projeto inovador: um Tour Virtual 360º imersivo, que leva os visitantes a explorar o Complexo de Sustentabilidade do aeroporto. Com uma experiência visualmente impactante, nosso time de técnicos esteve presente durante todo o evento, auxiliando os participantes com informações e suporte personalizado, garantindo que cada detalhe do tour fosse aproveitado de forma envolvente e educativa. Essa colaboração destacou o compromisso da ABE Eventos com a inovação em experiências sustentáveis e imersivas.",
    imgsUrl: [
      "https://upcdn.io/12a1z8V/raw/abeventos/images/oln3keq7bvqz4w83u5hw.jpg"
    ]
  },
  {
    title: "XI Congresso Brasileiro de Cirurgia do Fígado, Pâncreas e Vias Biliares",
    description: "No icônico Wish Hotel da Bahia, em Salvador, a ABE Eventos, em parceria com a Eventus System, contribuiu para o sucesso do XI Congresso Brasileiro de Cirurgia do Fígado, Pâncreas e Vias Biliares. Fornecemos uma estrutura completa com a locação de Totens Interativos e TVs, além de realizar a Produção de Conteúdo do evento, visando atender à retomada presencial com alta qualidade e interatividade. Este congresso, que reuniu experts nacionais e internacionais, foi um marco para a disseminação de conhecimento na cirurgia HPB, contando com discussões práticas e colaborativas. A ABE Eventos orgulha-se em fazer parte de um evento que, além de educativo, celebra a conexão entre colegas em um dos locais mais emblemáticos de Salvador.",
    imgsUrl: [
      "https://upcdn.io/12a1z8V/raw/abeventos/images/gya4kl4fvgbhcaxd2587.jpg",
      "https://upcdn.io/12a1z8V/raw/abeventos/images/nabquz34svddrrxrkf4i.jpg",
      "https://upcdn.io/12a1z8V/raw/abeventos/images/eibm67w1osspfrtitkd8.jpg",
      "https://upcdn.io/12a1z8V/raw/abeventos/images/iltf0mcnwp9kinu3jqcs.jpg",
    ]
  },
  {
    title: "53º Congresso Brasileiro de Otorrinolaringologia e Cirurgia Cérvico-Facial",
    description: "Para a 53ª edição do Congresso Brasileiro de Otorrinolaringologia e Cirurgia Cérvico-Facial, realizado pela ABORL no Centro de Convenções de Salvador, a ABE Eventos proporcionou um credenciamento ágil e eficiente com Totens de Credenciamento e Impressão de Etiquetas, eliminando filas e facilitando o acesso dos participantes. Além disso, oferecemos nosso sistema de monitoramento com câmeras distribuídas estrategicamente para garantir segurança em todo o evento. Um dos destaques foi a Arena Silenciosa, que proporcionou um ambiente de palestras e discussões com foco e comodidade, enriquecendo a experiência dos congressistas. A ABE Eventos se orgulha em contribuir para um evento dinâmico, seguro e tecnicamente avançado.",
    imgsUrl: [
      "https://upcdn.io/12a1z8V/raw/abeventos/images/favd41opcruocf6mr4zv.png",
      "https://upcdn.io/12a1z8V/raw/abeventos/images/aly5sieiqrmiwylv2ck9.jpg",
      "https://upcdn.io/12a1z8V/raw/abeventos/images/ttljvoiwqcw8lshpkxhq.jpg",
    ]
  },
  {
    ...FeaturedEvent
  }
];