import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { EventInfo } from "../../services/EventsInfo";

import BackgroundContainer from "../../components/BackgroundContainer";
import Header from "../../components/Header";
import Navigator from "../../components/Navigator";
import EventContainer from "../../components/EventContainer";
import FeaturedEvent from "../../components/FeaturedEvent";
import RecentEvents from "../../components/RecentEvents";
import PointLight from "../../components/PointLight";
import { FeaturedEvent as FeaturedEventData, Events as EventsData } from '../../services/EventsInfo';

import { IoCloseCircle } from "react-icons/io5";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Controller, Navigation } from "swiper/modules";

import '../../styles/content.css';
import 'swiper/css';
import 'swiper/css/navigation';

const modalVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: [0.04, 0.62, 0.23, 0.98] 
    }
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  },
  exit: {
    scale: 0.95,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  }
};

export default function Events() {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInfo>();

  const handleEventSelect = (event: EventInfo) => {
    setSelectedEvent(event);
    setModalVisible(true);
  }

  const handleEventClose = () => {
    setModalVisible(false);
    setSelectedEvent(undefined)
  }

  return (
    <div className="Events flex flex-col h-full">
      <Header selectedId="events" device="desktop"/>
      
      <BackgroundContainer className="px-10 xl:px-32 py-4">
        <div className="z-10 absolute flex justify-center items-center pointer-events-none">
          <PointLight
            larger
            tiny
          />  
        </div>
        <div className="z-20 flex flex-col gap-y-4 2xl:gap-y-12">
          <div className="flex flex-col gap-y-4">
            <h1 className='text-white text-3xl 2xl:text-4xl max-w-md font-bold leagueSpartanFont underline decoration-solid'>
              Destaque
            </h1>

            <FeaturedEvent 
              event={FeaturedEventData}
              onEventClick={() => handleEventSelect(FeaturedEventData)}
            />
          </div>

          <div className="flex flex-col gap-y-4">
            <h1 className='text-white text-3xl 2xl:text-4xl max-w-md font-bold leagueSpartanFont underline decoration-solid'>
              Eventos recentes
            </h1>

            <div className="flex gap-x-3">
              <RecentEvents 
                events={EventsData} 
                onEventClick={(event: EventInfo) => handleEventSelect(event)}
              />
            </div>
          </div>
        </div>
        <Navigator selectedId="events"/>
      </BackgroundContainer>
      <AnimatePresence>
        {(modalVisible && selectedEvent) && (
          <div 
            className="product-modal w-screen h-screen fixed z-40 flex justify-center items-center" 
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            onClick={handleEventClose}
          >
            <motion.div
              className="w-[700px] h-[620px] bg-black rounded-lg border border-white text-white px-8 py-4 flex flex-col gap-4 relative"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="absolute top-2 right-2 hover:scale-110 transition-all duration-200"
                onClick={handleEventClose}
              > 
                <IoCloseCircle size={30} />
              </button>
              <div className="flex flex-col items-center gap-4 justify-around h-full">
                <h2
                  className="text-center text-2xl font-black"
                >
                  {selectedEvent.title}
                </h2>
                <div className="flex flex-col items-center gap-4">
                  <div
                    className="flex max-w-96 select-none"
                  >
                    <button
                      onClick={() => swiperControl!.slidePrev()}
                      className="z-40 text-[#ff9900]"
                    >
                      <IoIosArrowBack
                        size={35}
                        className="hover:scale-110 transition-all duration-300"
                      />
                    </button>
                    <Swiper
                      onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
                      direction="horizontal"
                      loop={true}
                      slidesPerView={1}
                      modules={[Navigation, Controller]}
                    >
                      {selectedEvent.imgsUrl.map((imgUrl, index) => (
                        <SwiperSlide key={imgUrl}>
                          <img 
                            className="w-96 h-72 object-cover"
                            src={imgUrl} 
                            alt="" 
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <button
                      onClick={() => swiperControl!.slideNext()}
                      className="z-40 text-[#ff9900]"
                    >
                      <IoIosArrowForward
                        size={35}
                        className="hover:scale-110 transition-all duration-300"
                      />
                    </button>
                  </div>
                  <p className="text-sm text-center">{selectedEvent.description}</p>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}