import React, { useState } from "react";
import ProductContainer from "../ProductContainer";
import truncateString from "../../utils/truncateString";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ProductInfo } from "../../services/ProductsInfo";
import { Controller, Navigation } from "swiper/modules";

interface ProductsListProps {
  mobile?: boolean;
  products: ProductInfo[];
  onProductOpen: (product: ProductInfo) => void;
}

export default function ProductsList({
  mobile,
  products,
  onProductOpen
}: ProductsListProps) {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();

  return (
    <>
      {mobile ? (
        <div className="flex max-w-[300px]">
          <button 
            className="text-white z-40"
            onClick={() => swiperControl!.slidePrev()}
          >
            <IoIosArrowBack size={35} />
          </button>
          <Swiper
            onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
            direction="horizontal"
            spaceBetween={5}
            loop={true}
            slidesPerView={1}
            modules={[Navigation, Controller]}
          >
            {products.map((product, index) => (
              <SwiperSlide>
                <ProductContainer 
                  mobile
                  title={product.title}
                  text={truncateString(product.text, 20)}
                  onOpen={() => onProductOpen(product)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className="text-white z-40"
            onClick={() => swiperControl!.slideNext()}
          >
            <IoIosArrowForward size={35} />
          </button>
        </div>
      ) : (
        <div className="flex justify-center flex-wrap mt-3 2xl:mt-6 gap-1 xl:gap-4 2xl:gap-3 max-w-screen-2xl">
          {products.map((product, index) => (
            <ProductContainer 
              title={product.title}
              text={truncateString(product.text, 55)}
              onOpen={() => onProductOpen(product)}
            />
          ))}
        </div>    
      )}
    </>
  );
}