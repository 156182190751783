import React from "react";

interface ContactTitleProps {
  mobile?: boolean;
}

export default function ContactTitle({mobile}: ContactTitleProps) {
  return (
    <h1 className={`text-white text-5xl font-bold leagueSpartanFont ${mobile ? 'mb-6' : 'relative top-5'} 2xl:top-20`}>Fale conosco</h1>
  );
}