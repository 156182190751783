import React from "react";
import CountUp from "react-countup";
import { FaTrophy } from "react-icons/fa6";

const easingExpoInOut = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
  t--;
  return c / 2 * (-Math.pow(2, -15 * t) + 2) + b;
};

interface LeaderStatistcProps {
  small?: boolean;
  breakLine?: boolean;
}

export default function LeaderStatistc({ 
  small = false,
  breakLine = false
}: LeaderStatistcProps) {
  return (
    <div>
      <div className="flex gap-2 items-center">
        <h2 className="bg-gradient-to-t from-[#FF6B00] to-[#FFA41C] bg-clip-text text-transparent text-3xl font-bold clash-display">1°</h2>
        <FaTrophy size={25} color="#FFA41C" />
      </div>
      <h3 className={`${small ? 'text-xs' : 'text-sm'}`}>
        Líder em totens no mercado
      </h3>
    </div>
  );
}