import React from "react";

interface PointLightProps {
  tiny?: boolean;
  larger?: boolean;
}

export default function PointLight({
  tiny,
  larger
}: PointLightProps) {
  return (
    <>
      <div className={`pointer-events-none relative bg-[#CC00FF] ${larger ? 'w-[560px]' : 'w-[260px]'} ${larger ? 'h-[560px]' : 'h-[260px]'} ${tiny ? 'opacity-10' : 'opacity-40'} animate-spin`} style={{filter: 'blur(200px)'}} />
      <div className={`pointer-events-none relative bg-[#FFD600] ${larger ? 'w-[400px]' : 'w-[200px]'} ${larger ? 'h-[400px]' : 'h-[200px]'} bottom-40 ${tiny ? 'opacity-10' : 'opacity-40'} animate-spin`} style={{filter: 'blur(150px)'}} />
    </>
  );
}