import React from "react";
import InfoButton from "../InfoButton";
import { EventInfo } from "../../services/EventsInfo";

interface FeaturedEventProps {
  mobile?: boolean;
  event: EventInfo;
  onEventClick: () => void;
}

export default function FeaturedEvent({
  mobile,
  event,
  onEventClick
}: FeaturedEventProps) {
  return (
    <div className={`flex items-center ${mobile ? 'flex-col justify-center mt-8' : 'gap-x-12'}`}>
      <div className="relative">
        <img 
          className="h-36 lg:h-44 2xl:h-48" 
          src={event.imgsUrl[0]} 
          alt=""
        />

        {!mobile && (
          <InfoButton onClick={onEventClick} />
        )}
      </div>
      

      <div className={`mt-6 ${mobile ? 'max-w-72 text-center' : ''}`}>
        <h2 className="text-white text-xl 2xl:text-3xl max-w-md font-black">{event.title}</h2>
        
        <p className="text-white text-xs lg:text-base 2xl:text-base max-w-xl font-medium mt-4">
        {event.description}<br />
        <a 
          className="underline decoration-solid text-orange-400 cursor-pointer font-bold"
          onClick={onEventClick}
        >
          Continuar lendo
        </a>
        </p>
      </div>
    </div>
  );
}