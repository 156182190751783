import React from 'react'
import Link from './Link';
import { RouteInfo } from '.';

interface Props{
  selectedPageId?: "home" | "company" | "clients" | "contact" | "events" | "products";
  headerData: RouteInfo[];
}

export default function DesktopItems({selectedPageId, headerData}: Props){
  return(
    <div className='bg-black w-screen flex py-4 px-10 xl:px-32 text-md 2xl:text-lg items-center'>
      <a href={headerData[0].route}>
        <img className='h-10 mr-12' src="https://upcdn.io/12a1z8V/raw/abeventos/images/cdapepvc87qotqq59sdq.png" alt="Logo Abe Eventos" />
      </a>
      
      <ul className='flex gap-10'>
        {headerData.map((data) => (
          <Link key={`link-${data.id}`} route={data.route} selected={data.id == selectedPageId}>{data.content}</Link>
        ))}
      </ul>
    </div>
  )
}