import React from 'react'
import Link from './Link'

interface NavigatorProps {
  selectedId?: "home" | "company" | "clients" | "contact" | "events" | "products";
  className?: string;
  fill?: boolean;
}

const navigatorData = [
  {
    id: "home",
    route: "/",
    content: ''
  },
  {
    id: "company",
    route: "/empresa",
    content: ''
  },
  {
    id: "clients",
    route: "/clientes",
    content: ''
  },
  {
    id: "contact",
    route: "/contato",
    content: ''
  },
  {
    id: "events",
    route: "/eventos",
    content: ''
  },
  {
    id: "products",
    route: "/produtos",
    content: ''
  }
]

export default function Navigator({
  fill, 
  selectedId, 
  className
}: NavigatorProps){
  return(
    <ul className={`${fill ? 'w-full ms-64' : ''} flex gap-x-2 items-center absolute bottom-5 2xl:bottom-10 ${className}`}>
      {navigatorData.map((data) => 
        (<Link key={`link-${data.id}`} route={data.route} selected={data.id == selectedId}>{data.content}</Link>
      ))}
    </ul>
  )
}