import React, { useState } from "react";
import EventContainer from "../EventContainer";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import 'swiper/css';
import 'swiper/css/navigation';
import { Controller, Navigation } from "swiper/modules";
import { EventInfo } from "../../services/EventsInfo";

interface RecentEventsProps {
  mobile?: boolean;
  events: EventInfo[];
  onEventClick: (event: EventInfo) => void;
}

export default function RecentEvents({
  mobile,
  events,
  onEventClick
}: RecentEventsProps) {
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={`flex ${mobile ? 'max-w-[320px]' : 'max-w-[650px]'} select-none`}>
      <button 
        className="z-40 text-[#ff9900]"
        onClick={() => swiperControl!.slidePrev()}
      >
        <IoIosArrowBack 
          size={35} 
          className="hover:scale-110 transition-all duration-300"
        />
      </button>
      <Swiper
        onSwiper={(swiper: SwiperClass) => setSwiperControl(swiper)}
        onSlideChange={() => swiperControl && setActiveIndex(swiperControl.activeIndex)}
        direction="horizontal"
        spaceBetween={10}
        loop={true}
        slidesPerView={mobile ? 2 : 3}
        modules={[Navigation, Controller]}
      >
        {events.map((event, index) => (
          <SwiperSlide key={index}>
            <EventContainer 
              event={event}
              onClick={() => onEventClick(event)}
              mobile={mobile}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        className="z-40 text-[#ff9900]"
        onClick={() => swiperControl!.slideNext()}
      >
        <IoIosArrowForward size={35} className="hover:scale-110 transition-all duration-300" />
      </button>
    </div>
  );
}