import React from "react";

interface CompanySubtitle {
  mobile?: boolean;
}

export default function CompanySubtitle({mobile}: CompanySubtitle) {
  return (
    <h2 className={`text-white ${mobile ? 'text-md' : 'text-xs'} xl:text-md 2xl:text-lg font-black`}>
      Criatividade e qualidade é o nosso lema!
    </h2>
  );
}